import styled, { keyframes } from 'styled-components';

export const WrapperDiv = styled.div`
  width: 100%;
  max-width: 1280px;
  padding: 0 25px;
  margin: auto;
`;

export const ProductTitleDiv = styled.div`
  font-size: 18px;
  font-weight: bold;
  text-transform: capitalize;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  @media only screen and (max-width: 480px) {
    font-size: 14px;
  }
`;

export const ProductPriceDiv = styled.div`
  font-size: 14px;
  color: #4D4D4D;
  font-weight: 500;
`;

export const ProductLabelDiv = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: #4D4D4D;
`;

const spinneAnimation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const Spinner = styled.div<{ size: number, color: string }>`
  border: ${(props) => (props.size / 8)}px solid #ffffff29; /* Light grey */
  border-top: ${(props) => (props.size / 8)}px solid ${(props) => props.color}; /* Blue */
  border-radius: 50%;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  animation: ${spinneAnimation} 1s linear infinite;
  margin: 0 auto;
`;

export const SelectContainerDiv = styled.div`
  display: inline-block;
  background-color: #E21E2D;
  padding: 15px 0;
  padding-right: 15px;
  border-radius: 5px;
  box-shadow: 0 3px 6px #00000029;

  > select {
    background-color: transparent;
    border: none;
    color: #ffffff;
    font-size: 14px;
    outline: none;
    padding: 0 15px;
    padding-right: 5px;
  }
`;
