import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '..';
import type { IMenu } from '../../typings';
import https from '../../utils/https';

interface MenuState {
  items: Array<IMenu>
  item: IMenu
}

const initialState: MenuState = {
  items: [],
  item: {} as IMenu,
};

export const fetchMenus = createAsyncThunk(
  'menu/fetchMenus',
  async (restaurantId: string) => {
    const response = await https.get(`/menus/restaurant/${restaurantId}`);
    return response.data;
  },
);

export const fetchMenu = createAsyncThunk(
  'menu/fetchMenu',
  async (menuId: string, thunkApi) => {
    const { menu } = thunkApi.getState() as RootState;
    const item = menu.items.find((m) => m._id === menuId);

    if (item) {
      return item;
    }

    const response = await https.get(`/menus/${menuId}`);
    return response.data;
  },
);

const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchMenus.fulfilled, (state, action: PayloadAction<Array<IMenu>>) => {
      state.items = action.payload;
    });

    builder.addCase(fetchMenu.fulfilled, (state, action: PayloadAction<IMenu>) => {
      state.item = action.payload;
    });
  },
});

// export const {} = menuSlice.actions;
export default menuSlice.reducer;
