import React, { useEffect } from 'react';
import styled from 'styled-components';

// import foodOne from '../../assets/img/food-1.jpg';
// import foodTwo from '../../assets/img/food-2.jpg';
// import foodThree from '../../assets/img/food-5.jpg';
// import foodFour from '../../assets/img/food-6.jpg';

const ContainerDiv = styled.div<{ isPrimary: boolean }>`
  padding: 50px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  border-bottom: 1px solid #dddddd;

  :last-child {
    border-bottom: none;
  }

  > div {
    ${(props) => (props.isPrimary
    ? `
        :first-child {
          font-size: 32px;
          font-weight: bold;
          flex: 1;
          text-align: center;
          position: relative;
        }

        :nth-child(2) {
          flex: 1;
          font-size: 18px;
          line-height: 35px;
        }
        ` : `
          :first-child {
            flex: 1;
            font-size: 18px;
            line-height: 35px;
          }

          :nth-child(2) {
            font-size: 32px;
            font-weight: bold;
            flex: 1;
            text-align: center;
            position: relative;
          }
        `)}
  }

  @media screen and (max-width: 500px) {
    display: inherit;
  }
`;

// const FoodImg = styled.img<{ isPrimary: boolean }>`
//   position: absolute;
//   top: -50px;
//   width: 100%;
//   max-width: 200px;
//   border-radius: 5px;
//   z-index: -1;

//   ${(props) => (
//     props.isPrimary
//       ? (`
//         left: 0;
//       `) : (`
//         right: 0;
//       `)
//   )}

//   @media screen and (max-width: 500px) {
//     visibility: hidden;
//   }
// `;

const TitleDiv = styled.div`
  text-align: center;
  font-size: 32px;
  padding-top: 50px;
  padding-bottom: 10px;
`;

const LineDiv = styled.div`
  margin: auto;
  border-bottom: 4px solid #000000;
  width: 10%;
  margin-bottom: 50px;
`;

const AboutUsPage: React.FC = () => {
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <div style={{ backgroundColor: 'transparent' }}>
      <TitleDiv>
        Who are we?
      </TitleDiv>
      <LineDiv />
      <ContainerDiv isPrimary>
        <div>
          Our Story
          {/* <FoodImg src={foodOne} isPrimary /> */}
        </div>
        <div>
          {`
            Our Restaurants are known for its Its Exotic Food & Service Prepared with a traditional and Cultures. We provide authentic Indian Muslim (mamak) food & service with enthusiastic approach. Famous for Mee Goreng and Exotic Curries cooked fresh in our kitchen.
          `}
          <br />
          <br />
          {`
            Our goal is to serve our customers with enthusiasm. Our menu includes a fine selection of popular and some unique Indian Muslim and Malay dishes.
          `}
        </div>
      </ContainerDiv>
      <ContainerDiv isPrimary={false}>
        <div>
          {`
            Before The Independence Of Malaysia, In 1952, Late. Abdul Muthalif Started A Stall To Provide A Food For The People In Balik Pulau, Penang With Traditional Flavours And Spices As A Small Business; After The Overwhelming Support From The Patrons, He Established A Small Business In 1955, So There Thaqwa Was Founded.
          `}
        </div>
        <div>
          1st Generation
          {/* <FoodImg style={{ maxWidth: 200 }} src={foodTwo} isPrimary={false} /> */}
        </div>
      </ContainerDiv>
      <ContainerDiv isPrimary>
        <div>
          2st Generation
          {/* <FoodImg style={{ maxWidth: 200 }} src={foodThree} isPrimary /> */}
        </div>
        <div>
          {`
            In 1976, Abdul Muthalif’s Son Mohamed Ishaq Opened An Another Stall In Old Klang Road, Kuala Lumpur., With The Experience He Learned From His Father. It Provides Food And Service To The Customers From Dusk To Dawn And Later It Became One Of The Pioneer In Mamak Stalls. After The Eminence Of The Stall In Kl, He Continued To Open An Another Stall In Selayang Jaya. (1981)
          `}
        </div>
      </ContainerDiv>
      <ContainerDiv isPrimary={false}>
        <div>
          {`
            Few Years Later, In 2008, Riaz Kan Son Of Ahmad Lattheeb Has Been Partnered With Abul Harid Son Of Mohamed Ishaq Opened A Restaurant In 2008 At Balakong Jaya, Seri Kembangan Which Was A Big Start Up And Marking A Step Into Restaurant Industry. The Popularity Of Our Food And Service Has Allowed Us To Expand From Our Small Restaurant Stalls Into Proper Restaurant Aspects. Both Riaz Kan And Abul Harid Were Became One Of The Best Partners In Restaurant Industry After A Random Meeting; Out Of The Great Efforts And Mission, It Was Grown Further And Now It Has Became 10 Outlets Up-To-Date, Which Provide Restaurant Service With Enthusiasm.
          `}
          <br />
          <br />
        </div>
        <div>
          3st Generation
          {/* <FoodImg src={foodFour} isPrimary={false} /> */}
        </div>
      </ContainerDiv>
      <ContainerDiv isPrimary>
        <div>
          Moving Forward
        </div>
        <div>
          {`
            The Founders Of Restoran Thaqwa Strive For Constant Perfection And Innovation. Our Partners, Chefs, And Staffs Take Tremendous Pride And Pleasure In Creating And Serving Every Food And An Experience To Go With It. We Have Been Humbled With The Support And Are Ever More Committed To Our Customers’ Satisfaction.
          `}
        </div>
      </ContainerDiv>
    </div>
  );
};

export default AboutUsPage;
