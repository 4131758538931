import { IMG_BASE_URL } from '../constants';

const formattedImgUrl = (images: Array<string>): string => {
  if (images.length > 0) {
    return `${IMG_BASE_URL}/${images[0]}`;
  }

  return '';
};

export default formattedImgUrl;
