const DEV_URL = 'https://thaqwa-api-pa4sb7lioa-as.a.run.app/';
const PRODUCTION_URL = 'https://thaqwa-api-pa4sb7lioa-as.a.run.app/';

const getApiUrl = (): string => {
  let apiUrl = DEV_URL;
  const { hostname } = window.location;

  if (hostname === 'thawqaclassic.com') {
    apiUrl = PRODUCTION_URL;
  }

  return apiUrl;
};

export default getApiUrl;
