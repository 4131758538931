const emailValidation = (email: string): boolean => {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
};

const mobileNoValidation = (number: string): boolean => (/^\d+$/).test(number);

const stringValidation = (value: string): boolean => (value.trim() !== '');

export {
  emailValidation,
  stringValidation,
  mobileNoValidation,
};
