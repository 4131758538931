import { configureStore } from '@reduxjs/toolkit';

// reducers
import categoriesReducer from './categories/categoriesSlice';
import restaurantsReducer from './restaurants/restaurantsSlice';
import menuReducer from './menu/menuSlice';
import cartReducer from './cart/cartSlice';

const store = configureStore({
  reducer: {
    categories: categoriesReducer,
    restaurants: restaurantsReducer,
    menu: menuReducer,
    cart: cartReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
