import React from 'react';
import styled, { keyframes } from 'styled-components';

interface ModalProps {
  isShowModal: boolean
  title: string
  content: React.ReactNode
  bottom: React.ReactNode
}

const slideFromTopAnimation = keyframes`
  from { margin-top: -100px; opacity: 0; }
  to { margin-top: 0; opacity: 1; }
`;

const ContainerDiv = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: #00000029;
  width: 100%;
  height: 100%;
  z-index: 9999999;
`;

const ModalContainerDiv = styled.div`
  background-color: #ffffff;
  border-radius: 5px;
  padding: 20px 0;
  margin: 0 auto;
  max-width: 400px;
  width: 100%;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  animation: ${slideFromTopAnimation} 0.15s linear;
`;

const ModalTitleDiv = styled.div`
  font-size: 16px;
  padding: 0 20px 20px;
  font-weight: bold;
  border-bottom: 1px solid #cccccc;
  margin-bottom: 20px;
`;

const ModalDescDiv = styled(ModalTitleDiv)`
  font-weight: normal;
`;

const ContentBottomDiv = styled.div`
  padding: 0 20px;
`;

const Modal: React.FC<ModalProps> = ({
  isShowModal,
  title,
  content,
  bottom,
}) => (isShowModal ? (
  <ContainerDiv>
    <ModalContainerDiv>
      <ModalTitleDiv>
        {title}
      </ModalTitleDiv>
      <ModalDescDiv>
        {content}
      </ModalDescDiv>
      <ContentBottomDiv>
        {bottom}
      </ContentBottomDiv>
    </ModalContainerDiv>
  </ContainerDiv>
) : null);

export default Modal;
