import React, { useState } from 'react';
import styled from 'styled-components';

interface InputProps {
  label: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  placeholder?: string
  value: string | number
  errorMessage?: string
  isError?: boolean
  type?: string
}

const InputLabelDiv = styled.div`
  font-size: 12px;
  color: #4D4D4D;
  margin-bottom: 5px;
`;

const StyledInput = styled.input`
  outline: none;
  border: 1px solid #cccccc;
  border-radius: 4px;
  padding: 10px;
  width: 100%;
  background-color: #ffffff;
  font-size: 14px;

  ::placeholder {
    color: #cccccc;
  }
`;

const ErrorMessageDiv = styled.div`
  color: red;
  font-size: 10px;
  padding-top: 2.5px;
`;

const Input: React.FC<InputProps> = (props: InputProps) => {
  const {
    label,
    value,
    placeholder = '',
    errorMessage = '',
    type = 'text',
    isError = false,
    onChange,
  } = props;

  const [isShowError, setIsShowError] = useState<boolean>(false);

  return (
    <>
      <InputLabelDiv>
        {label}
      </InputLabelDiv>
      <StyledInput
        value={value}
        placeholder={placeholder}
        type={type}
        onChange={onChange}
        onChangeCapture={() => { setIsShowError(true); }}
      />
      {isShowError && errorMessage !== '' && isError
        && (
          <ErrorMessageDiv>{errorMessage}</ErrorMessageDiv>
        )}
      <div style={{ marginBottom: 15 }} />
    </>
  );
};

Input.defaultProps = {
  placeholder: '',
  errorMessage: '',
  isError: false,
};

export default Input;
