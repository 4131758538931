import React from 'react';
import styled, { keyframes } from 'styled-components';
import { CheckCircleIcon } from '@heroicons/react/outline';

// Components
import Button from '../../../components/Button/Button';

interface SuccessModalProps {
  onClickOkay: () => void
  isShowModal: boolean
}

const slideFromTopAnimation = keyframes`
  from { margin-top: -100px; opacity: 0; }
  to { margin-top: 0; opacity: 1; }
`;

const ContainerDiv = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: #00000029;
  width: 100%;
  height: 100%;
  z-index: 9999999;
`;

const ModalContainerDiv = styled.div`
  background-color: #ffffff;
  border-radius: 5px;
  padding: 20px 0;
  margin: 0 auto;
  max-width: 400px;
  width: 100%;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  animation: ${slideFromTopAnimation} 0.15s linear;
`;

const ModalTitleDiv = styled.div`
  font-size: 16px;
  padding: 0 20px 20px;
  font-weight: bold;
  border-bottom: 1px solid #cccccc;
  margin-bottom: 20px;
  text-align: center;
`;

const ModalDescDiv = styled(ModalTitleDiv)`
  font-weight: normal;
  text-align: center;
`;

const ModalButtonContainer = styled.div`
  padding: 0 20px;
  width: 100%;
`;

const SuccessModal: React.FC<SuccessModalProps> = ({
  isShowModal,
  onClickOkay,
}) => (isShowModal ? (
  <ContainerDiv>
    <ModalContainerDiv>
      <div style={{ textAlign: 'center' }}>
        <CheckCircleIcon
          width={60}
          height={60}
          style={{ color: '#18A558' }}
        />
      </div>
      <ModalTitleDiv>
        You reservation request is successfull
      </ModalTitleDiv>
      <ModalDescDiv>
        Staff from Thaqwa Classic will contact you via
        {' '}
        email or phone call to confirm your reservation.
      </ModalDescDiv>
      <ModalButtonContainer>
        <Button type="primary" onClick={onClickOkay} display="block">
          OK
        </Button>
      </ModalButtonContainer>
    </ModalContainerDiv>
  </ContainerDiv>
) : null);

export default SuccessModal;
