/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { ShoppingBagIcon, MenuIcon, XIcon } from '@heroicons/react/outline';
import { Link, useHistory, useLocation } from 'react-router-dom';

import logo2x from '../../assets/img/logo@2x.png';
import { useAppSelector } from '../../store/hooks';
import { getItemsNumber } from '../../store/cart/cartSlice';

const ContainerDiv = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  left: 0;
  right: 0;
  background-color: #ffffff;
  box-shadow: 0px 3px 6px #00000029;
  z-index: 999999;
`;

const slideFromTopAnimation = keyframes`
  from { margin-top: -100px; opacity: 0; }
  to { margin-top: 0; }
  100% { opacity: 1; }
`;

const SubContainerDiv = styled.div`
  width: 100%;
  max-width: 1280px;
  margin: auto;
  padding: 0 25px;

  .mobile-container {
    display: none;

    @media screen and (max-width: 900px) {
      display: block;
      animation: ${slideFromTopAnimation} 0.15s linear;
    }
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    
    :first-child {
      @media screen and (max-width: 900px) {
        display: none;
      }
    }

    :last-child {
      display: none;

      @media screen and (max-width: 900px) {
        display: flex;
      }
    }

    li {
      margin-right: 30px;
      float: right;
      font-weight: 500;
      font-size: 18px;
      cursor: pointer;
      padding: 10px 0;
      width: 100px;
      text-align: right;

      :first-child {
        float: left;
        flex: 1;
        text-align: left;

        :hover {
          opacity: 1;
        }
      }

      :last-child {
        margin-right: 0;
        width: 50px;
      }

      :hover {
        opacity: 0.85;
      }
    }

  }
`;

const CartNumberDiv = styled.div`
  position: absolute;
  z-index: 99;
  top: 20px;
  margin-left: 15px;
  background-color: #E21E2D;
  color: #ffffff;
  border-radius: 100%;
  width: 22px;
  height: 22px;
  font-size: 12px;
  text-align: center;
  padding-top: 3px;
`;

const NavLabelDiv = styled.div`
  font-weight: 500;
  font-size: 18px;
  cursor: pointer;
  padding: 15px 0px;
`;

const LIST = [
  {
    name: 'Menu',
    pathname: '/',
  },
  {
    name: 'About Us',
    pathname: '/about-us',
  },
  {
    name: 'Branches',
    pathname: '/branches',
  },
  {
    name: 'Reservations',
    pathname: '/reservations',
  },
];

const Navbar: React.FC = () => {
  const numOfItems = useAppSelector(getItemsNumber);
  const location = useLocation();
  const history = useHistory();
  const [isShowNavbar, setIsShowNavbar] = useState<boolean>(false);

  return (
    <>
      <ContainerDiv>
        <SubContainerDiv>
          <ul>
            <li>
              <img width="80" src={logo2x} alt="Thaqwa Classic" />
            </li>
            {LIST.map((each) => (
              <li
                onClick={() => { history.push(each.pathname); }}
                key={each.name}
                style={{ fontWeight: each.pathname === location.pathname ? 'bold' : 'normal' }}
              >
                {each.name}
              </li>
            ))}
            <li style={{ opacity: numOfItems > 0 ? 1 : 0.4 }}>
              {numOfItems > 0
                ? (
                  <Link to="/checkout">
                    <div style={{ position: 'relative', zIndex: 999 }}>
                      <ShoppingBagIcon width={30} height={30} />
                    </div>
                    <CartNumberDiv>{numOfItems}</CartNumberDiv>
                  </Link>
                ) : (
                  <div style={{ position: 'relative', zIndex: 999 }}>
                    <ShoppingBagIcon width={30} height={30} />
                  </div>
                )}
            </li>
          </ul>
          <ul>
            <li>
              <img width="80" src={logo2x} alt="Thaqwa Classic" />
            </li>
            <li>
              {isShowNavbar
                ? (
                  <XIcon
                    onClick={() => { setIsShowNavbar(!isShowNavbar); }}
                    width={30}
                    height={30}
                  />
                ) : (
                  <MenuIcon
                    onClick={() => { setIsShowNavbar(!isShowNavbar); }}
                    width={30}
                    height={30}
                  />
                )}
            </li>
          </ul>

          {isShowNavbar
            && (
              <div className="mobile-container">
                {LIST.map((each) => (
                  <NavLabelDiv
                    onClick={() => {
                      history.push(each.pathname);
                      setIsShowNavbar(!isShowNavbar);
                    }}
                    key={each.name}
                    style={{ fontWeight: each.pathname === location.pathname ? 'bold' : 'normal' }}
                  >
                    {each.name}
                  </NavLabelDiv>
                ))}
              </div>
            )}
        </SubContainerDiv>
      </ContainerDiv>
      <div style={{ height: 90, width: '100%' }} />
    </>
  );
};

export default Navbar;
