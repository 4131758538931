import React from 'react';
import styled from 'styled-components';

import { WrapperDiv } from '../../components/styles';
import CategorySidebar from '../../components/Sidebar/CategorySidebar';
import MenuSection from './components/MenuSection';

import bannerImg from '../../assets/img/food-1-min.jpg';

const BannerContainerDiv = styled.div`
  background-color: #ffffff;
  width: 100%;
  padding-top: 33.5%;
  margin-top: 20px;
  box-shadow: 2px 2px 10px #00000029;
  border-radius: 7px;
  position: relative;
`;

const BannerImgDiv = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-image: url(${bannerImg});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 7px;
`;

const MenuContainerDiv = styled.div`
  content: "";
  clear: both;
  display: table;
  margin-top: 30px;
  width: 100%;

  > div {
    float: left;

    :first-child {
      width: 20%;
    }

    :nth-child(2) {
      width: 80%;
      padding-left: 30px;
    }

    @media screen and (max-width: 900px) {
      :first-child {
        width: 100%;
        padding-bottom: 20px;
      }

      :nth-child(2) {
        width: 100%;
        padding-left: 0;
      } 
    }
  }
`;

const HomePage: React.FC = () => (
  <WrapperDiv>
    <BannerContainerDiv>
      <BannerImgDiv />
    </BannerContainerDiv>

    <MenuContainerDiv>
      <div>
        <CategorySidebar />
      </div>

      <div>
        <MenuSection />
      </div>
    </MenuContainerDiv>
  </WrapperDiv>
);

export default HomePage;
