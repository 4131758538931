import React from 'react';
import styled from 'styled-components';
import Button from '../../../components/Button/Button';
import Modal from '../../../components/Modal/Modal';

interface WarningModalProps {
  onClickYes: () => void
  onClickCancel: () => void
  isShowModal: boolean
}

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

const WarningModal: React.FC<WarningModalProps> = ({
  isShowModal,
  onClickCancel,
  onClickYes,
}) => (
  <Modal
    isShowModal={isShowModal}
    title="Are you sure you want to change restaurant?"
    content="You will lost all your item in the cart if you change the restaurant."
    bottom={(
      <ButtonContainer>
        <Button type="secondary" onClick={onClickCancel}>
          Cancel
        </Button>
        <div style={{ display: 'inline-block', padding: '0 10px' }} />
        <Button type="primary" onClick={onClickYes}>
          Yes
        </Button>
      </ButtonContainer>
    )}
  />
);

export default WarningModal;
