import React, { lazy, Suspense } from 'react';
import {
  BrowserRouter,
  Switch,
  Route,
} from 'react-router-dom';

// Components
import Navbar from './components/Navbar/Navbar';
import Toast from './components/Toast/Toast';
import Footer from './components/Footer/Footer';

// Page
import HomePage from './pages/Home/HomePage';
import AboutUsPage from './pages/AboutUs/AboutUsPage';
import BranchesPage from './pages/Branches/BranchesPage';
import ReservationsPage from './pages/Reservations/ReservationsPage';
import ContactUsPage from './pages/ContactUs/ContactUsPage';

const ProductPage = lazy(() => import('./pages/Product/ProductPage'));
const CheckoutPage = lazy(() => import('./pages/Checkout/CheckoutPage'));
const OrderPage = lazy(() => import('./pages/Order/OrderPage'));

const Router: React.FC = () => (
  <BrowserRouter>
    <Toast />
    <Navbar />
    <Suspense fallback={<div>Loading...</div>}>
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route exact path="/menu/:menuId" component={ProductPage} />
        <Route exact path="/menu/:menuId/edit/:cartIndex" component={ProductPage} />
        <Route exact path="/checkout" component={CheckoutPage} />
        <Route exact path="/order/:orderId" component={OrderPage} />
        <Route exact path="/about-us" component={AboutUsPage} />
        <Route exact path="/branches" component={BranchesPage} />
        <Route exact path="/reservations" component={ReservationsPage} />
        <Route exact path="/contact-us" component={ContactUsPage} />
      </Switch>
    </Suspense>
    <Footer />
  </BrowserRouter>
);

export default Router;
