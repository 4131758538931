import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import queryString from 'query-string';

import { fetchCategories } from '../../store/categories/categoriesSlice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';

const ContainerDiv = styled.div`
  box-shadow: 2px 2px 10px #00000026;
  border-radius: 7px;
  background-color: #ffffff;
  width: 100%;
  overflow: hidden;

  @media screen and (max-width: 900px) {
    height: 250px;
    overflow: scroll;
  }
`;

const TitleDiv = styled.div`
  font-weight: bold;
  font-size: 16px;
  padding: 20px 25px;
  top: 0;
  position: sticky;
  background-color: #ffffff;
`;

const ListDiv = styled.div`
  color: ${(props: { isActive: boolean }) => (props.isActive ? '#E21E2D' : '#707070')};
  font-size: 16px;
  padding: 15px 25px;
  cursor: pointer;
  font-weight: ${(props: { isActive: boolean }) => (props.isActive ? 'bold' : '500')};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-transform: capitalize;

  :hover {
    background-color: #eeeeee;
  }
`;

const CategorySidebar: React.FC = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const categories = useAppSelector((state) => state.categories.items);
  // eslint-disable-next-line max-len
  const parsedSearch: { cId: string } = queryString.parse(history.location.search) as { cId: string };

  const [isLoading, setIsLoading] = useState<boolean>(categories.length === 0);

  const categoryRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    const setCategories = async () => {
      await dispatch(fetchCategories());
      setIsLoading(false);
    };

    if (isLoading) {
      setCategories();
    }
  }, [dispatch, isLoading]);

  const onClickCategory = (categoryId: string) => {
    if (categoryRef && categoryRef.current) {
      categoryRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }

    history.replace(`/?cId=${categoryId}`);
  };

  return (
    <ContainerDiv>
      <TitleDiv ref={categoryRef}>
        Category
      </TitleDiv>

      <div style={{ borderBottom: '1px solid #cccccc' }} />

      {isLoading
        ? (
          <ListDiv isActive={false}>
            Loading...
          </ListDiv>
        ) : (
          categories.map((c) => (
            <ListDiv
              key={c._id}
              isActive={parsedSearch.cId ? c._id === parsedSearch.cId : c._id === categories[0]._id}
              onClick={() => onClickCategory(c._id)}
            >
              {c.name.toLowerCase()}
            </ListDiv>
          ))
        )}
    </ContainerDiv>
  );
};

export default CategorySidebar;
