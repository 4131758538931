import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';

import type { ICategory } from '../../typings';
import https from '../../utils/https';

interface CategoriesState {
  items: Array<ICategory>;
}

const initialState: CategoriesState = {
  items: [],
};

export const fetchCategories = createAsyncThunk(
  'categories/fetchCategories',
  async () => {
    const response = await https.get('/categories');
    return response.data;
  },
);

const counterSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchCategories.fulfilled,
      (state, action: PayloadAction<Array<ICategory>>) => {
        state.items = action.payload;
      },
    );
  },
});

export default counterSlice.reducer;
