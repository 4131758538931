import {
  createSlice, createAsyncThunk, PayloadAction, createSelector,
} from '@reduxjs/toolkit';

import type { RootState } from '..';
import type { IRestaurant } from '../../typings';
import https from '../../utils/https';

interface RestaurantState {
  items: Array<IRestaurant>;
  item: IRestaurant;
}

const initialState: RestaurantState = {
  items: [],
  item: {} as IRestaurant,
};

export const fetchRestaurants = createAsyncThunk(
  'restaurants/fetchRestaurants',
  async () => {
    const response = await https.get('/restaurants');
    return response.data;
  },
);

const restaurantsSlice = createSlice({
  name: 'restaurants',
  initialState,
  reducers: {
    setSelectedRestaurant: (state, action: PayloadAction<string>) => {
      const restaurant = state.items.find((r) => r._id === action.payload) || ({} as IRestaurant);
      state.item = { ...restaurant };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchRestaurants.fulfilled,
      (state, action: PayloadAction<Array<IRestaurant>>) => {
        state.items = action.payload;
      },
    );
  },
});

export const getFullAddress = createSelector(
  (state: RootState) => state.restaurants.item,
  (item) => {
    if (item.name) {
      return (`
        ${item.addressOne} ${item.addressTwo || ''} ${item.city} ${item.postcode} ${item.state}
      `);
    }

    return '';
  },
);

export const { setSelectedRestaurant } = restaurantsSlice.actions;
export default restaurantsSlice.reducer;
