import React from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import Router from './router';

import store from './store';
import { BgContainer } from './styles';

// import thaqwaSpoonLogo from './assets/svg/thaqwa-spoon-logo-nobg.svg';

const theme = {};

const App: React.FC = () => (
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <div className="App">
        <BgContainer />
        <Router />
      </div>
    </ThemeProvider>
  </Provider>
);

export default App;
