import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import logo from '../../assets/img/logo.png';

const ContainerDiv = styled.div`
  margin: auto;
  text-align: right;
  padding: 30px 10%;
  width: 100%;
  color: #666666;
  display: flex;
  width: 100%;
  margin: auto;
  margin-top: 50px;
  align-items: center;

  @media screen and (max-width: 900px) {
    margin-bottom: 100px;
  }

  @media screen and (max-width: 448px) {
    display: block;
    padding: 30px 10px;
  }

  @media screen and (max-width: 448px) {
    > div:nth-child(2) {
      text-align: center;
    } 
  }
`;

const CopyrightSectionDiv = styled.div`
  flex: 1;
  text-align: left;

  > div {
    display: inline-block;

    :nth-child(2) {
      position: relative;
      top: -10px;
      z-index: -1;

      @media screen and (max-width: 448px) {
        font-size: 11px;
      }
    }
  }

  @media screen and (max-width: 448px) {
    margin-bottom: 15px;
    text-align: center;
  }
`;

const LinkSpan = styled.span`
  cursor: pointer;
  margin-left: 10%;
  color: #666666;

  @media screen and (max-width: 448px) {
    margin: 0;
    :nth-child(2) {
      margin: 0 5%;
    } 
  }
`;

const LogoImg = styled.img`
  width: 60px;
  padding-right: 20px;
`;

const Footer: React.FC = () => (
  <ContainerDiv>
    <CopyrightSectionDiv>
      <div>
        <LogoImg src={logo} />
      </div>
      <div>
        © Thaqwa Classic 2021. All right reserved
      </div>
    </CopyrightSectionDiv>
    <div style={{ flex: 1 }}>
      <LinkSpan>
        <Link to="/branches">
          Branches
        </Link>
      </LinkSpan>
      <LinkSpan>
        <Link to="/about-us">
          About Us
        </Link>
      </LinkSpan>
      <LinkSpan>
        <Link to="/contact-us">
          Contact Us
        </Link>
      </LinkSpan>
    </div>
  </ContainerDiv>
);

export default Footer;
