import React from 'react';
import styled from 'styled-components';
import { MapIcon, ChevronRightIcon } from '@heroicons/react/outline';

// Components
import { WrapperDiv } from '../../components/styles';

// Constant
import { branches } from '../../constants';

// Logo
import logo from '../../assets/img/logo.png';

const TitleDiv = styled.div`
  text-align: center;
  font-size: 32px;
  padding-top: 30px;
  padding-bottom: 10px;
`;

const LineDiv = styled.div`
  margin: auto;
  border-bottom: 4px solid #000000;
  width: 10%;
  margin-bottom: 50px;
`;

const ContainerDiv = styled.div`
  content: "";
  clear: both;
  display: table;
  width: 100%;

  > div {
    float: left;
    width: 45%;
    font-size: 18px;
    margin: 0 2.5% 5%;

    @media screen and (max-width: 500px) {
      margin: 0 0 5%;
      width: 100%;
    }
  }
`;

const NameDiv = styled.div`
  font-weight: bold;
  text-transform: capitalize;
  padding-bottom: 10px;
`;

const InfoLabelDiv = styled.div`
  margin-bottom: 10px;
`;

const SubContainerDiv = styled.div`
  background-color: #ffffff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 3px 6px #00000029;
  position: relative;
`;

const LogoContainerDiv = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;

  img {
    width: 40px;
  }
`;

const DirectionsBtnDiv = styled.div`
  display: inline-block;
  cursor: pointer;
  color: #30839f;
  font-size: 16px;

  .right-icon {
    transition: all 500ms;
    position: relative;
    top: 6.5px;
    right: 0px;
  }

  :hover {
    .right-icon {
      right: 5px;
    }
  }
`;

const BranchesPage: React.FC = () => (
  <WrapperDiv style={{ maxWidth: 1000 }}>
    <TitleDiv>
      Find Us
      <MapIcon style={{ position: 'relative', top: 15, left: 10 }} width={50} height={50} />
    </TitleDiv>
    <LineDiv />
    <ContainerDiv>
      {branches.map((each) => (
        <SubContainerDiv key={each.id}>
          <LogoContainerDiv>
            <img src={logo} alt="logo" />
          </LogoContainerDiv>
          <NameDiv>
            {each.name.toLowerCase()}
          </NameDiv>
          <InfoLabelDiv>
            {each.phone}
          </InfoLabelDiv>

          <InfoLabelDiv>
            {each.address}
          </InfoLabelDiv>

          <DirectionsBtnDiv
            onClick={() => {
              window.open(each.link, '_blank');
            }}
          >
            Get Directions
            {' '}
            <ChevronRightIcon
              width={24}
              height={24}
              className="right-icon"
            />
          </DirectionsBtnDiv>
        </SubContainerDiv>
      ))}
    </ContainerDiv>
  </WrapperDiv>
);

export default BranchesPage;
