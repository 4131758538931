import styled from 'styled-components';
import thawaSpoonLogo from './assets/svg/thaqwa-spoon-logo-nobg.svg';

export const BackgroundThaqwaImage = styled.img``;

export const BgContainer = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: -1;

  background-image: url(${thawaSpoonLogo});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  margin-top: 5%;
`;
