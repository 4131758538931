const branches = [
  {
    id: 1,
    name: 'Balakong Jaya',
    address: 'No.1, Jalan Balakong Jaya 6, Taman Balakong Jaya, 43300 Seri Kembangan, Selangor',
    link: 'https://ul.waze.com/ul?ll=3.01095870%2C101.75247840&navigate=yes',
    phone: '03-8957 8987',
  },
  {
    id: 2,
    name: 'Subang–Ara Damansara',
    address: 'Blok A No.8,A-G-1, Jalan PJU 1A/20A, Dataran Ara Damansara, 47301 Petaling Jaya, Selangor',
    link: 'https://ul.waze.com/ul?ll=3.12021840%2C101.57975800&navigate=yes',
    phone: '03-7831 0934',
  },
  {
    id: 3,
    name: 'Bandar Saujana Putra',
    address: 'No.1, Jalan SPU 2, Bandar Saujana Putra, 42610 Jenjarom, Selangor',
    link: 'https://ul.waze.com/ul?ll=2.95178520%2C101.58309820&navigate=yes',
    phone: '03-5103 5826',
  },
  {
    id: 4,
    name: 'Bandar Seri Putra',
    address: 'No.1, Jalan Seri Putra 1/2, Bandar Seri Putra, 43000 Kajang, Selangor',
    link: 'https://ul.waze.com/ul?ll=2.88521420%2C101.79175360&navigate=yes',
    phone: '03-8923 1786',
  },
  {
    id: 5,
    name: 'Taman Setia Balakong',
    address: 'Taman Setia Balakong , Dataran Ara Damansara, Balakong, 43300 Seri Kembangan, Selangor',
    link: 'https://ul.waze.com/ul?ll=3.01377800%2C101.74921310&navigate=yes',
    phone: '03-7831 0934',
  },
  {
    id: 6,
    name: 'Semenyih',
    address: 'No.19, jalan Kesuma 3/11, Bandar Tasik Kesuma Beranang, 43700 Semenyih, Selangor',
    link: 'https://ul.waze.com/ul?ll=2.90572890%2C101.86523500&navigate=yes',
    phone: '012-379 0087',
  },
  {
    id: 7,
    name: 'Sha Alam Seksyen 7',
    address: '27, Jalan Kristal As7/As, Seksyen 7, 40000 Shah Alam, Selangor',
    link: 'https://ul.waze.com/ul?ll=3.07638210%2C101.48871460&navigate=yes',
    phone: '03-5523 5901',
  },
  {
    id: 8,
    name: 'Shah Alam Seksyen 19',
    address: 'No.1, Jalan Nelayan A 19/A, Seksyen 19, 40300 Shah Alam, Selangor',
    link: 'https://ul.waze.com/ul?ll=3.04864510%2C101.53334140&navigate=yes',
    phone: '03-5545 8800',
  },
  {
    id: 9,
    name: 'Cheras Traders Square',
    address: 'No, L 18G & 19G , Jalan Dataran Cheras 9 Cheras Balakong, 43200 Cheras, Selangor',
    link: 'https://ul.waze.com/ul?ll=3.03449299%2C101.76432841&navigate=yes&zoom=17',
    phone: '012-379 0087',
  },
  {
    id: 10,
    name: 'Bandar Sri Permaisuri',
    address: 'No.2, Jalan Danan Lumayan 1, Off, Jalan Tasik Permaisuri 1, Bandar Sri Permaisuri, 56000 Kuala Lumpur',
    link: 'https://ul.waze.com/ul?ll=3.10084870%2C101.71432550&navigate=yes',
    phone: '03-9081 0306',
  },
];

export default branches;
