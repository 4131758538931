import React, { useState } from 'react';
import styled from 'styled-components';
import { IdentificationIcon } from '@heroicons/react/outline';
import moment from 'moment';

// Components
import { SelectContainerDiv, Spinner, WrapperDiv } from '../../components/styles';
import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';

// Utils
import { emailValidation, mobileNoValidation, stringValidation } from '../../utils/validations';
import { branches } from '../../constants';
import https from '../../utils/https';
import SuccessModal from './components/SuccessModal';
import { showToast } from '../../components/Toast/Toast';

const TitleDiv = styled.div`
  text-align: center;
  font-size: 32px;
  padding-top: 30px;
  padding-bottom: 10px;
`;

const LineDiv = styled.div`
  margin: auto;
  border-bottom: 4px solid #000000;
  width: 10%;
  margin-bottom: 50px;
`;

const SelectLabelDiv = styled.div`
  font-size: 12px;
  color: #4D4D4D;
  margin-bottom: 10px;
`;

const ReservationsPage: React.FC = () => {
  const [name, setName] = useState<string>('');
  const [mobileNo, setMobileNo] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [date, setDate] = useState<string>('');
  const [time, setTime] = useState<string>('');
  const [guest, setGuest] = useState<number>(2);
  const [restaurant, setRestaurant] = useState<string>(branches[0].name);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isShowModal, setIsShowModal] = useState<boolean>(false);

  const errorChecking = {
    name: !stringValidation(name),
    restaurant: !stringValidation(restaurant),
    mobileNo: !mobileNoValidation(mobileNo),
    email: !emailValidation(email),
    date: !stringValidation(date),
    time: !stringValidation(time),
    guest: guest < 2,
  };

  const isError = (): boolean => {
    const errors = Object.values(errorChecking);
    return errors.includes(true);
  };

  const onChangeRestaurants = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    setRestaurant(e.target.value);
  };

  const onClickBook = async () => {
    const body = {
      name,
      email,
      date: moment(date).format('DD MMM YYYY'),
      time,
      phoneNumber: mobileNo,
      guestNo: guest,
      restaurant,
      code: 'TQ',
    };

    try {
      setIsLoading(true);
      await https.post('/restaurants/reservation', body);
      setIsShowModal(true);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      showToast({
        type: 'error',
        title: 'Reservation failed!',
        subtitle: 'There is an error trying to make reservations. We are working on it.',
      });
    }
  };

  const onClickOk = () => {
    setIsShowModal(false);
    setName('');
    setMobileNo('');
    setEmail('');
    setDate('');
    setTime('');
    setGuest(2);
  };

  return (
    <WrapperDiv style={{ maxWidth: 500 }}>
      <SuccessModal
        isShowModal={isShowModal}
        onClickOkay={onClickOk}
      />

      <TitleDiv>
        Reservations
        <IdentificationIcon style={{ position: 'relative', top: 15, left: 10 }} width={50} height={50} />
      </TitleDiv>
      <LineDiv />

      <SelectLabelDiv>
        Restaurant
      </SelectLabelDiv>
      <SelectContainerDiv style={{ marginBottom: 20 }}>
        <select onChange={onChangeRestaurants} defaultValue={restaurant}>
          {branches.map((each) => (
            <option key={each.id} value={each.name}>
              {each.name}
            </option>
          ))}
        </select>
      </SelectContainerDiv>

      <Input
        label="Name"
        value={name}
        placeholder="e.g John Doe"
        isError={errorChecking.name}
        errorMessage="Name is required!"
        onChange={(e) => { setName(e.target.value); }}
      />
      <Input
        label="Mobile No."
        value={mobileNo}
        placeholder="e.g 0123456789"
        isError={errorChecking.mobileNo}
        errorMessage={!stringValidation(mobileNo) ? 'Mobile number is required!' : 'Mobile number is invalid!'}
        onChange={(e) => { setMobileNo(e.target.value); }}
      />
      <Input
        label="Email"
        value={email}
        placeholder="e.g john@doe.com"
        isError={errorChecking.email}
        errorMessage={!stringValidation(email) ? 'Email is required!' : 'Email is invalid!'}
        onChange={(e) => { setEmail(e.target.value); }}
      />

      <div style={{ display: 'flex' }}>
        <div style={{ flex: 1, paddingRight: 10 }}>
          <Input
            label="Date"
            value={date}
            type="date"
            isError={errorChecking.date}
            errorMessage="Date is required!"
            onChange={(e) => { setDate(e.target.value); }}
          />
        </div>
        <div style={{ flex: 1, paddingLeft: 10 }}>
          <Input
            label="Time"
            value={time}
            type="time"
            isError={errorChecking.time}
            errorMessage="Time is required!"
            onChange={(e) => { setTime(e.target.value); }}
          />
        </div>
      </div>

      <Input
        label="No. of Guest"
        value={guest}
        placeholder="e.g 1"
        type="number"
        isError={errorChecking.guest}
        errorMessage="Minimum 2 guest!"
        onChange={(e) => { setGuest(Number(e.target.value)); }}
      />

      <div style={{ marginBottom: 30 }} />

      <Button type="primary" display="block" onClick={onClickBook} disabled={isError() || isLoading}>
        {isLoading ? <Spinner color="#ffffff" size={18} /> : 'Book'}
      </Button>
    </WrapperDiv>
  );
};

export default ReservationsPage;
