import React from 'react';
import styled from 'styled-components';
import {
  ClockIcon,
  InformationCircleIcon,
  LocationMarkerIcon,
  MailIcon,
  PhoneIcon,
} from '@heroicons/react/outline';

// Components
import { WrapperDiv } from '../../components/styles';

const TitleDiv = styled.div`
  text-align: center;
  font-size: 32px;
  padding-top: 30px;
  padding-bottom: 10px;
`;

const LineDiv = styled.div`
  margin: auto;
  border-bottom: 4px solid #000000;
  width: 10%;
  margin-bottom: 50px;
`;

const ContainerDiv = styled.div`
  width: 100%;
  box-shadow: 0 3px 6px #00000029;
  border-radius: 5px;
  background-color: #ffffff;
  padding: 30px;
`;

const InfoContainerDiv = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  > div {
    :first-child {
      flex: 1.5
    }

    :nth-child(2) {
      flex: 8.5;
    }
  }

  :last-child {
    margin-bottom: 0;
  }
`;

const ContactUsPage: React.FC = () => (
  <WrapperDiv style={{ maxWidth: 500 }}>
    <TitleDiv>
      Contact Us
      <InformationCircleIcon style={{ position: 'relative', top: 15, left: 10 }} width={50} height={50} />
    </TitleDiv>
    <LineDiv />
    <ContainerDiv>

      <InfoContainerDiv>
        <div>
          <LocationMarkerIcon width={30} height={30} />
        </div>
        <div>
          No.1, Jalan Balakong Jaya 6, Taman Balakong Jaya, 43300 Seri Kembangan, Selangor
        </div>
      </InfoContainerDiv>

      <InfoContainerDiv>
        <div>
          <ClockIcon width={30} height={30} />
        </div>
        <div>
          9am - 6pm
        </div>
      </InfoContainerDiv>

      <InfoContainerDiv>
        <div>
          <MailIcon width={30} height={30} />
        </div>
        <div>
          contact@thaqwaclassic.com
        </div>
      </InfoContainerDiv>

      <InfoContainerDiv>
        <div>
          <PhoneIcon width={30} height={30} />
        </div>
        <div>
          03-8953 8034
        </div>
      </InfoContainerDiv>
    </ContainerDiv>
  </WrapperDiv>
);

export default ContactUsPage;
