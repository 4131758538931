import React from 'react';
import Button from '../../../components/Button/Button';
import Modal from '../../../components/Modal/Modal';
import { SelectContainerDiv } from '../../../components/styles';
import { useAppSelector } from '../../../store/hooks';

interface RestaurantModalProps {
  onChangeRestaurant: (e: React.ChangeEvent<HTMLSelectElement>) => void,
  onClickOk: () => void
  isShowModal: boolean
}

const RestaurantModal: React.FC<RestaurantModalProps> = ({
  isShowModal,
  onClickOk,
  onChangeRestaurant,
}) => {
  const restaurants = useAppSelector((state) => state.restaurants.items);
  const restaurant = useAppSelector((state) => state.restaurants.item);

  return (
    <Modal
      isShowModal={isShowModal}
      title="Select restaurant"
      content={(
        <SelectContainerDiv>
          <select onChange={onChangeRestaurant} value={restaurant._id}>
            {restaurants.length === 0
              ? (
                <option value="loading">Loading...</option>
              ) : (
                restaurants.map((each) => (
                  <option key={each._id} value={each._id}>
                    {each.name}
                  </option>
                ))
              )}
          </select>
        </SelectContainerDiv>
      )}
      bottom={(
        <Button type="primary" onClick={onClickOk} display="block">
          Ok
        </Button>
      )}
    />
  );
};

export default RestaurantModal;
