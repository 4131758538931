const DEV_URL = 'https://api.thaqwaclassic.com/product/images';
const PRODUCTION_URL = 'https://api.thaqwaclassic.com/product/images';

const imgBaseUrl = (): string => {
  let baseUrl = DEV_URL;
  const { hostname } = window.location;

  if (hostname === 'thawqaclassic.com') {
    baseUrl = PRODUCTION_URL;
  }

  return baseUrl;
};

export default imgBaseUrl;
