import React, { useEffect, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { CheckCircleIcon, XIcon, XCircleIcon } from '@heroicons/react/outline';

const slideFromLeft = keyframes`
  from {
    right: -300px;
    opacity: 0;
  }
  
  to {
    right: 20px;
    opacity: 1;
  }
`;

const exitAnimation = keyframes`
  from {
    right: 20px;
    opacity: 1;
  }

  to {
    right: -300px;
    opacity: 0;
  }
`;

const ContainerDiv = styled.div<{ isVisible: boolean }>`
  border-radius: 7px;
  padding: 20px;
  background-color: #ffffff;
  position: fixed;
  top: 100px;
  right: 20px;
  box-shadow: 0 3px 6px #00000029;
  display: flex;
  z-index: 9999999;
  visibility: ${(props) => (props.isVisible ? 'visible' : 'hidden')};
  ${(props) => (
    props.isVisible ? css`animation: ${slideFromLeft} 0.5s, ${exitAnimation} 0.5s 2.5s;` : ''
  )};
`;

const TitleDiv = styled.div`
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 10px;

  @media screen and (max-width: 448px) {
    font-size: 14px;
  }
`;

const DescDiv = styled.div`
  font-size: 16px;
  color: #AAAAAA;

  @media screen and (max-width: 448px) {
    font-size: 12px;
  }
`;

let showToastVisible: (body: ToastType) => void;
let hideToastVisible: () => void;
let timeout: NodeJS.Timeout;

type ToastType = {
  type: 'success' | 'error'
  title: string
  subtitle: string
};

const RightToast: React.FC = () => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [toast, setToast] = useState<ToastType>(({} as ToastType));

  useEffect(() => {
    showToastVisible = (body: ToastType): void => {
      setToast(body);
      setIsVisible(true);
      timeout = setTimeout(() => {
        setIsVisible(false);
      }, 3000);
    };

    hideToastVisible = (): void => {
      setIsVisible(false);
    };
  }, []);

  const renderIcon = () => {
    if (toast.type === 'error') {
      return (
        <XCircleIcon
          width={24}
          height={24}
          color="#E21E2D"
          style={{ position: 'relative', top: -1 }}
        />
      );
    }

    return (
      <CheckCircleIcon
        width={24}
        height={24}
        color="#18A558"
        style={{ position: 'relative', top: -1 }}
      />
    );
  };

  return (
    <ContainerDiv isVisible={isVisible}>
      <div>
        {renderIcon()}
      </div>
      <div style={{ padding: '0 10px' }}>
        <TitleDiv>
          {toast.title}
        </TitleDiv>
        <DescDiv>
          {toast.subtitle}
        </DescDiv>
      </div>
      <div>
        <XIcon
          width={20}
          height={20}
          color="#AAAAAA"
          style={{ cursor: 'pointer' }}
          onClick={() => {
            clearTimeout(timeout);
            setIsVisible(false);
          }}
        />
      </div>
    </ContainerDiv>
  );
};

export const showToast = (toast: ToastType): void => {
  showToastVisible(toast);
};

export const hideToast = (): void => {
  hideToastVisible();
};

export default RightToast;
