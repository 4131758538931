import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';

// Custom hooks
import { useAppDispatch, useAppSelector } from '../../../store/hooks';

// Typings
import { IMenu } from '../../../typings';

// Components
import OrderSidebar from '../../../components/Sidebar/OrderSidebar';
import ProductCardSmall from './ProductCardSmall';
import { SelectContainerDiv } from '../../../components/styles';

// Redux Actions
import { fetchRestaurants, setSelectedRestaurant } from '../../../store/restaurants/restaurantsSlice';
import { fetchMenus } from '../../../store/menu/menuSlice';
import { resetCart } from '../../../store/cart/cartSlice';
import WarningModal from './WarningModal';
import RestaurantModal from './RestaurantModal';

const TitleDiv = styled.div`
  font-size: 28px;
  font-weight: bold;
  color: #222222;
  margin-bottom: 20px;
  text-transform: capitalize;
`;

const ProductGridDiv = styled.div`
  content: "";
  clear: both;
  display: table;
  width: 100%;
  
  > div {
    float: left;
    width: 30%;
    margin-bottom: 25px;

    :nth-child(3n + 2) {
      margin-left: 5%;
      margin-right: 5%;
    }

    @media only screen and (max-width: 480px) {
      width: 45%;

      :nth-child(3n + 2) {
        margin-left: 0;
        margin-right: 0;
      }

      :nth-child(odd) {
        margin-right: 5%;
      }

      :nth-child(even) {
        margin-left: 5%;
      }
    }
  }
`;

const ContainerDiv = styled.div`
  content: "";
  clear: both;
  display: table;
  margin-top: 30px;
  width: 100%;

  > div {
    float: left;

    :first-child {
      width: 70%;
      padding-right: 25px;
    }

    :nth-child(2) {
      width: 30%;
      position: sticky;
      top: 100px;
    }

    @media screen and (max-width: 900px) {
      :first-child {
        width: 100%;
        padding-right: 0px;
      }

      :nth-child(2) {
        width: 100%;
        padding-top: 20px;
      } 
    }
  }
`;

const MenuSection: React.FC = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const restaurants = useAppSelector((state) => state.restaurants.items);
  const items = useAppSelector((state) => state.menu.items);
  const categories = useAppSelector((state) => state.categories.items);
  const restaurant = useAppSelector((state) => state.restaurants.item);
  const cartItems = useAppSelector((state) => state.cart.items);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isShowWarningModal, setIsShowWarningModal] = useState<boolean>(false);
  const [restaurantId, setRestaurantId] = useState<string>('');
  const [isShowRestaurantModal, setIsShowRestaurantModal] = useState<boolean>(false);

  useEffect(() => {
    const getRestaurantsAndMenus = async () => {
      setIsLoading(true);
      const { payload } = await dispatch(fetchRestaurants());
      dispatch(setSelectedRestaurant(payload[0]._id));
      setRestaurantId(payload[0]._id);
      dispatch(fetchMenus(payload[0]._id));
      setIsLoading(false);
    };

    if (items.length === 0) {
      getRestaurantsAndMenus();
    }
  }, []);

  const getSelectedCategory = (): { name: string, _id: string } => {
    const parseSearch = queryString.parse(history.location.search);

    if (categories.length === 0) {
      return { name: 'Loading', _id: '' };
    }

    if (parseSearch.cId) {
      const category = categories.find((c) => c._id === parseSearch.cId);
      return category || categories[0];
    }

    return categories[0];
  };

  const changeRestaurant = async (value: string) => {
    setIsLoading(true);
    await dispatch(setSelectedRestaurant(value));
    await dispatch(fetchMenus(value));
    await dispatch(resetCart());
    setIsLoading(false);
  };

  const onChangeRestaurants = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    setRestaurantId(e.target.value);

    if (cartItems.length > 0) {
      setIsShowWarningModal(true);
    } else {
      changeRestaurant(e.target.value);
    }
  };

  const onClickProduct = (item: IMenu) => {
    const isShowed: boolean = window.sessionStorage.getItem('restaurantPrompt') === 'showed';
    if (isShowed) {
      if (item.status === 'ACTIVE') {
        history.push(`/menu/${item._id}`);
      }
    } else {
      setIsShowRestaurantModal(true);
      window.sessionStorage.setItem('restaurantPrompt', 'showed');
    }
  };

  return (
    <>
      <WarningModal
        isShowModal={isShowWarningModal}
        onClickCancel={() => {
          setIsShowWarningModal(false);
          setRestaurantId(restaurant._id);
        }}
        onClickYes={() => {
          setIsShowWarningModal(false);
          changeRestaurant(restaurantId);
        }}
      />

      <RestaurantModal
        isShowModal={isShowRestaurantModal}
        onChangeRestaurant={onChangeRestaurants}
        onClickOk={() => { setIsShowRestaurantModal(false); }}
      />

      <div style={{ marginBottom: 20 }}>
        <SelectContainerDiv>
          <select onChange={onChangeRestaurants} value={restaurant._id}>
            {restaurants.length === 0
              ? (
                <option value="loading">Loading...</option>
              ) : (
                restaurants.map((each) => (
                  <option key={each._id} value={each._id}>
                    {each.name}
                  </option>
                ))
              )}
          </select>
        </SelectContainerDiv>
      </div>

      <TitleDiv>{getSelectedCategory().name}</TitleDiv>

      <ContainerDiv>
        {isLoading
          ? (
            <div>Loading...</div>
          ) : (
            <div>
              <ProductGridDiv>
                {items.map((each) => (
                  each.product.category._id === getSelectedCategory()._id
                    && (
                      <ProductCardSmall
                        key={each._id}
                        item={each}
                        onClick={() => onClickProduct(each)}
                      />
                    )
                ))}
              </ProductGridDiv>
            </div>
          )}

        <div>
          <OrderSidebar />
        </div>
      </ContainerDiv>
    </>
  );
};

export default MenuSection;
