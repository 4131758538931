import {
  createSelector, createSlice, createAsyncThunk, PayloadAction,
} from '@reduxjs/toolkit';
import type { RootState } from '..';
import { TAX } from '../../constants';

// Utils
import https from '../../utils/https';

// Typings
import type { ICart, IOrder } from '../../typings';
import type IOrderBody from './typings';

interface CartState {
  items: Array<ICart>
  order: IOrder,
}

const initialState: CartState = {
  items: [],
  order: ({} as IOrder),
};

export const createOrder = createAsyncThunk(
  'cart/createOrder',
  async (body: IOrderBody, { rejectWithValue }) => {
    try {
      const response = await https.post('/orders', body);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const fetchOrder = createAsyncThunk(
  'cart/fetchOrder',
  async (orderId: string, { rejectWithValue }) => {
    try {
      const response = await https.get(`/orders/${orderId}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    resetCart: (state) => {
      state.items = [];
    },
    addItemToCart: (state, action: PayloadAction<ICart>) => {
      state.items.push(action.payload);
    },
    updateCartItem: (state, action: PayloadAction<{ index: number, item: ICart }>) => {
      const { index, item } = action.payload;
      state.items[index] = { ...item };
    },
    removeCartItem: (state, action: PayloadAction<number>) => {
      state.items.splice(action.payload, 1);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createOrder.fulfilled, (state, action: PayloadAction<IOrder>) => {
      state.order = action.payload;
      state.items = [];
    });

    builder.addCase(fetchOrder.fulfilled, (state, action: PayloadAction<IOrder>) => {
      state.order = action.payload;
    });
  },
});

export const getAmounts = createSelector(
  (state: RootState) => state.cart,
  (cart) => {
    let subtotalAmount = 0;
    let taxAmount = 0;
    let totalAmount = 0;

    cart.items.forEach((each) => {
      let pricePerItem = each.menu.takeAwayPrice || each.menu.price;
      each.extraSelections?.forEach((v) => {
        v.choices.forEach((c) => {
          pricePerItem += c.additionalPrice;
        });
      });

      subtotalAmount += (pricePerItem * each.quantity);
    });

    taxAmount = (subtotalAmount * (TAX / 100));
    totalAmount = subtotalAmount + taxAmount;

    return {
      subtotalAmount,
      taxAmount,
      totalAmount,
    };
  },
);

export const getItemsNumber = createSelector(
  (state: RootState) => state.cart,
  (cart) => cart.items.length,
);

export const {
  addItemToCart, removeCartItem, updateCartItem, resetCart,
} = cartSlice.actions;
export default cartSlice.reducer;
