import React from 'react';
import styled from 'styled-components';

interface ButtonProps {
  type: 'primary' | 'secondary'
  children: React.ReactNode
  onClick: () => void
  display?: 'inline-block' | 'block'
  disabled?: boolean
}

const BUTTON_THEME = {
  primary: '#E21E2D',
  secondary: '#ffffff',
};

const TEXT_COLOR = {
  primary: '#ffffff',
  secondary: '##666666',
};

const BORDER_COLOR = {
  primary: '#E21E2D',
  secondary: '#666666',
};

const ButtonDiv = styled.div<{ type: 'primary' | 'secondary', display: string, disabled: boolean }>`
  background-color: ${(props) => (props.disabled ? '#dddddd' : (BUTTON_THEME[props.type] || '#000000'))};
  border: 2px solid ${(props) => (props.disabled ? '#dddddd' : BORDER_COLOR[props.type] || '#000000')};
  border-radius: 5px;
  color: ${(props) => TEXT_COLOR[props.type] || '#ffffff'};
  display: ${(props) => props.display};
  padding: 14px 20px;
  font-size: 14px;
  font-weight: bold;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  opacity: 1;
  text-transform: uppercase;
  user-select: none;
  box-shadow: 0 3px 6px #00000029;
  text-align: center;

  ${(props) => !props.disabled
    && (`
      :hover {
        opacity: 0.8;
      }
    `)}
`;

const Button: React.FC<ButtonProps> = (props: ButtonProps) => {
  const {
    type, children, onClick, display = 'inline-block', disabled = false,
  } = props;

  return (
    <ButtonDiv
      type={type}
      onClick={disabled ? () => {} : onClick}
      display={display}
      disabled={disabled}
    >
      {children}
    </ButtonDiv>
  );
};

export default Button;
