import React from 'react';
import styled from 'styled-components';

// Components
import { ProductPriceDiv, ProductTitleDiv } from '../../../components/styles';
import type { IMenu } from '../../../typings';

// Svg
import photographOutlineSVG from '../../../assets/svg/photograph-outline.svg';
import formattedImgUrl from '../../../utils/formattedImgUrl';

interface ProductCardSmallProps {
  item: IMenu
  onClick: () => void
}

const ContainerDiv = styled.div<{ isAvailable: boolean }>`
  background-color: #ffffff;
  border-radius: 7px;
  box-shadow: 2px 2px 10px #00000026;
  overflow: hidden;
  cursor: ${(props) => (props.isAvailable ? 'pointer' : 'not-allowed')};
  transition: all 250ms;
  opacity: ${(props) => (props.isAvailable ? 1 : 0.65)};

  :hover {
    box-shadow: 1px 1px 5px #00000026;
  }
`;

const ProductImageDiv = styled.div<{ imgUrl: string }>`
  width: 100%;
  padding-top: 66%;
  background-image: url(${(props) => (props.imgUrl || photographOutlineSVG)});
  background-repeat: no-repeat;
  background-size: ${(props) => (props.imgUrl !== '' ? 'cover' : 'initial')};
  background-position: center;
  background-color: #eeeeee;
`;

const ProductCardSmall: React.FC<ProductCardSmallProps> = ({ item, onClick }) => (
  <ContainerDiv onClick={onClick} isAvailable={item.status === 'ACTIVE'}>
    <ProductImageDiv imgUrl={formattedImgUrl(item.product.images)} />
    <div style={{ margin: 15 }}>
      <ProductTitleDiv>
        {item.product.name.toLowerCase()}
      </ProductTitleDiv>
      {/* NOTE: Do not show in store price at the moment
      <ProductPriceDiv style={{ marginTop: 10 }}>
        {`RM ${item.price.toFixed(2)}`}
      </ProductPriceDiv> */}
      <ProductPriceDiv style={{ marginTop: 10, fontSize: 12 }}>
        {`RM ${item.takeAwayPrice ? item.takeAwayPrice.toFixed(2) : item.price.toFixed(2)}`}
      </ProductPriceDiv>
    </div>
  </ContainerDiv>
);

export default ProductCardSmall;
