import React from 'react';
import styled from 'styled-components';
import { ShoppingBagIcon, TrashIcon, PencilAltIcon } from '@heroicons/react/outline';
import { useHistory } from 'react-router-dom';

// Components
import Button from '../Button/Button';
import { showToast } from '../Toast/Toast';

// Hooks
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { removeCartItem, getAmounts } from '../../store/cart/cartSlice';
import { TAX } from '../../constants';
import { getFullAddress } from '../../store/restaurants/restaurantsSlice';
import MobileBottomBar from './MobileBottomBar';

const ContainerDiv = styled.div`
  box-shadow: 2px 2px 10px #00000026;
  border-radius: 7px;
  background-color: #ffffff;
  width: 100%;

  @media screen and (max-width: 900px) {
    display: none;
  }
`;

const TitleDiv = styled.div`
  font-weight: bold;
  font-size: 16px;
  padding: 20px 25px;
  color: #E21E2D;
`;

const PlaceholderDiv = styled.div`
  color: #AAAAAA;
  font-size: 14px;
  padding: 20px 25px;
`;

const PickupLabelDiv = styled.div`
  font-size: 12px;
  color: #4D4D4D;
  font-weight: bold;
  margin-bottom: 5px;
`;

const PickupAddressDiv = styled.div`
  > div {
    :first-child {
      font-size: 14px;
      font-weight: bold;
      text-transform: capitalize;
    }

    :nth-child(2) {
      font-size: 10px;
      color: #4D4D4D;
      margin-top: 5px;
    }
  }
`;

const AmountDiv = styled.div`
  font-size: 14px;
  color: #4D4D4D;
  text-align: left;
  margin-bottom: 10px;

  :last-child {
    margin-bottom: 0;
  }

  span {
    float: right;
  }
`;

const ItemContainerDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  padding: 10px 20px;

  > div {
    :first-child {
      align-self: flex-start;
      font-weight: bold;
      font-size: 14px;
      text-transform: capitalize;
      flex: 1;
    }

    :nth-child(2) {
      align-self: flex-start;
    }
  }
`;

const VariantDiv = styled.div`
  font-weight: 500;
  font-size: 12px;
  padding-top: 5px;
`;

const OrderSidebar: React.FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const cartItems = useAppSelector((state) => state.cart.items);
  const amounts = useAppSelector(getAmounts);
  const restaurant = useAppSelector((state) => state.restaurants.item);
  const address = useAppSelector(getFullAddress);

  const onClickRemove = (index: number) => {
    dispatch(removeCartItem(index));
    showToast({
      type: 'success',
      title: 'Successfully removed!',
      subtitle: 'Item successfully removed from cart.',
    });
  };

  const items = [];
  if (items.length > 0) {
    return (
      <Button type="primary" onClick={() => {}}>
        Order Pickup
        {' '}
        <ShoppingBagIcon width={18} height={18} style={{ position: 'relative', top: 2 }} />
      </Button>
    );
  }

  return (
    <>
      <MobileBottomBar />
      <ContainerDiv>
        <TitleDiv>
          My Order
          {cartItems.length > 0 ? ` (${cartItems.length})` : ' [Self Pickup]'}
        </TitleDiv>

        {cartItems.length > 0
          && (
            <>
              <div style={{ borderBottom: '1px solid #cccccc' }} />
              <div style={{ padding: '20px' }}>
                <PickupLabelDiv>PICK UP AT:</PickupLabelDiv>
                <PickupAddressDiv>
                  <div>
                    {restaurant.name.toLowerCase()}
                  </div>
                  <div>
                    {address}
                  </div>
                </PickupAddressDiv>
              </div>
            </>
          )}

        <div style={{ borderBottom: '1px solid #cccccc' }} />
        {cartItems.length > 0
          ? (
            cartItems.map((c, i) => (
              <ItemContainerDiv key={JSON.stringify(c)}>
                <div>
                  <div>
                    <span style={{ textTransform: 'lowercase' }}>
                      {`x${c.quantity} `}
                    </span>
                    {c.menu.product.name.toLowerCase()}
                  </div>
                  {c.extraSelections
                    && (
                    <div>
                      {c.extraSelections?.map((each) => (
                        each.choices.map((choice) => (
                          <VariantDiv key={choice.name}>
                            {`- ${choice.name}`}
                          </VariantDiv>
                        ))
                      ))}
                    </div>
                    )}
                  {c.specialInstructions
                    && (
                      <VariantDiv>
                        {`- [SP] ${c.specialInstructions}`}
                      </VariantDiv>
                    )}
                </div>

                <div style={{ cursor: 'pointer' }}>
                  <PencilAltIcon
                    width={20}
                    height={20}
                    onClick={() => {
                      history.push(`/menu/${c.menuId}/edit/${i}`);
                    }}
                  />
                  <span style={{ padding: '0 5px' }} />
                  <TrashIcon width={20} height={20} onClick={() => onClickRemove(i)} />
                </div>
              </ItemContainerDiv>
            ))
          ) : (
            <PlaceholderDiv>
              Awaiting your order.
            </PlaceholderDiv>
          )}
        <div style={{ borderBottom: '1px solid #cccccc' }} />

        <div style={{ padding: '20px 25px' }}>
          <AmountDiv>
            Subtotal
            <span>{`RM ${amounts.subtotalAmount.toFixed(2)}`}</span>
          </AmountDiv>
          <AmountDiv>
            {`Tax (${TAX}%)`}
            <span>{`RM ${amounts.taxAmount.toFixed(2)}`}</span>
          </AmountDiv>
          <AmountDiv style={{ fontWeight: 'bold' }}>
            Total (Incl. tax)
            <span>{`RM ${amounts.totalAmount.toFixed(2)}`}</span>
          </AmountDiv>

          {cartItems.length > 0
            && (
              <div style={{ marginTop: 30 }}>
                <Button type="primary" onClick={() => { history.push('/checkout'); }} display="block">
                  CHECKOUT
                </Button>
              </div>
            )}
        </div>
      </ContainerDiv>
      <div style={{ fontSize: 10, marginTop: 10 }}>
        Currently we only support self pickup. For delivery can order via Foodpanda or GrabFood.
      </div>
    </>
  );
};

export default OrderSidebar;
