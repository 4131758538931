import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { ShoppingBagIcon, ArrowRightIcon } from '@heroicons/react/outline';

// Hooks
import { useAppSelector } from '../../store/hooks';

const ContainerDiv = styled.div`
  background-color: #ffffff;
  padding: 20px 5%;
  flex-direction: row;
  box-shadow: 0 -3px 6px #00000029;
  width: 100%;
  cursor: pointer;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  align-items: center;
  display: none;
  z-index: 99999999;

  @media screen and (max-width: 900px) {
    display: flex;
  } 
`;

const ItemsTextDiv = styled.div`
  flex: 8;
  text-align: center;
  font-size: 18px;
  color: #E21E2D;
  font-weight: bold;
`;

const MobileBottomBar: React.FC = () => {
  const history = useHistory();
  const cartItems = useAppSelector((state) => state.cart.items);

  if (cartItems.length === 0) {
    return null;
  }

  return (
    <ContainerDiv onClick={() => { history.push('/checkout'); }}>
      <div style={{ flex: 1 }}>
        <ShoppingBagIcon width={30} height={30} />
      </div>
      <ItemsTextDiv>
        {`${cartItems.length} Items`}
      </ItemsTextDiv>
      <div style={{ flex: 1, textAlign: 'right' }}>
        <ArrowRightIcon width={30} height={30} />
      </div>
    </ContainerDiv>
  );
};

export default MobileBottomBar;
